<template>
    <div class="website">
        <div class="wrap">
            <h1 class="gt2 wow animated fadeInDown">
                <span class="gradient">{{$t('website.agreement.protocol')}}</span>
            </h1>

            <div class="m-txt1 wow animated fadeIn" v-html="$t('website.agreement.content')">
            </div>
        </div>
        <div class="toTop" :class="{show:isShow}" @click="backtop"></div>
    </div>
</template>
<script>
    import { WOW } from 'wowjs'
    export default {
        data() {
            return {
                isShow: document.documentElement.scrollTop || document.body.scrollTop >= 150,
            }
        },
        mounted() {
            this.wow();
            window.onscroll = () => {
                //变量t是滚动条滚动时，距离顶部的距离
                var t = document.documentElement.scrollTop || document.body.scrollTop;
                //当滚动到距离顶部150px时，返回顶部的按钮显示
                if (t >= 150) {
                    this.isShow = true
                } else {
                    this.isShow = false
                }
            }
        },
        methods: {
            backtop() {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            },
            wow() {
                if (!(/msie [6|7|8|9]/i.test(navigator.userAgent))) {// 在非 IE 6-9 浏览器中执行逻辑
                    var wow = new WOW({
                        boxClass: 'wow',
                        animateClass: 'animated',
                        offset: 50,
                        mobile: true,
                        live: true
                    });
                    this.$nextTick(() => {
                        wow.init();
                    });
                };
            },
        },
    }
</script>
<style scoped="scoped" lang="stylus">
    @import './agreement.styl';
    @import '~@/assets/style/frames.styl';
</style>